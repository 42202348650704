export const FontList = [
  {
    url: 'https://web.plugcustom.com.cn/plug-fonts/SourceHanSansCN-Bold.otf',
    name: '思源黑體',
    imgUrl: 'https://web.plugcustom.com.cn/plug-fonts/%E6%80%9D%E6%BA%90%E9%BB%91%E4%BD%93.png',
    checked: false,
  },
  {
    url: 'https://web.plugcustom.com.cn/plug-fonts/SoukouMincho.ttf',
    name: '裝甲明朝體',
    imgUrl: 'https://web.plugcustom.com.cn/plug-fonts/%E8%A3%85%E7%94%B2%E6%98%8E%E6%9C%9D%E4%BD%93.png',
    checked: false,
  },
  {
    url: 'https://web.plugcustom.com.cn/plug-fonts/%E4%BB%93%E8%80%B3%E8%88%92%E5%9C%86%E4%BD%93W03.ttf',
    name: '倉耳舒圓體',
    imgUrl: 'https://web.plugcustom.com.cn/plug-fonts/%E4%BB%93%E8%80%B3%E8%88%92%E5%9C%86%E4%BD%93.png',
    checked: false,
  },
  {
    url: 'https://web.plugcustom.com.cn/plug-fonts/%E6%B1%9F%E5%9F%8E%E6%AD%A3%E4%B9%89%E4%BD%93%20400W.ttf',
    name: '江城正義體',
    imgUrl: 'https://web.plugcustom.com.cn/plug-fonts/%E6%B1%9F%E5%9F%8E%E6%AD%A3%E4%B9%89%E4%BD%93.png',
    checked: false,
  },
]