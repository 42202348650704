import * as PIXI from 'pixi.js'
import { Application, Container, Graphics, Sprite, Text, TextStyle } from 'pixi.js'
import { convertImgToBase64, generateTexture, generateUUID, getIntegrateUrl, getSpriteTypeValue } from './utils'
// @ts-ignore
import initEvent from './initEvent'
// @ts-ignore
import initLoad from './initLoad'
import History, { IChangeItem, IHistoryItem, IHistoryItemType } from './history'
import type { CutoutType, IAddImageOption, IAlignOptions, IChildSprite, IConfigFontStyle, IGraphics, IImageContainer, IImageSprite, ILoadDesignDeviceScale, IParams, ISpriteIndexType, ITextSprite } from '@/types'
import { ColorOverlayFilter } from 'pixi-filters'
import { AppType } from '@/views/WebPixi/types'
import { CoreInitOption, MirrorType } from '../types'
import { loadImage } from '@/utils'
import { FontList } from './fontList'
// import DemoImage from '@/assets/images/image-list/sunflowers-8351807_1280.webp'
// import 'pixi-filters'

// const colorOverLay = new ColorOverlayFilter()
const CARTOON_TEMP = 'tplv-z8ialgywaa-cartoon-w'
const CUTOUT_TEMP = 'tplv-z8ialgywaa-cutout-w'
const WID_TEMP = 'tplv-z8ialgywaa-w-size'
const ORIGIN_TEMP = 'tplv-z8ialgywaa-image'

export const getUseImgTemp = (data: {
  cartoon?: boolean;
  cutout?: boolean;
  isLoadOrigin?: boolean;
}): string | undefined => {
  if (data.cartoon) {
    return CARTOON_TEMP
  } else if (data.cutout) {
    return CUTOUT_TEMP
  } else if (data.isLoadOrigin) {
    return ORIGIN_TEMP
  } else {
    return WID_TEMP
  }
}

export const getTextFontStyle = (sprite: ITextSprite) => {
  return {
    fontSize: sprite.style.fontSize,
    fontFamily: sprite.style.fontFamily,
    fill: sprite.style.fill,
    align: sprite.style.align,
    lineHeight: sprite.style.lineHeight,
    fontWeight: sprite.style.fontWeight ? Number(sprite.style.fontWeight) : 100,
    strokeThickness: sprite.style.strokeThickness,
    stroke: sprite.style.stroke,
  }
}

// export const 
interface SpriteChildItem {
  hasImageFill?: boolean
}

interface IOption {
  id?: number;
  width: number
  height: number
  type?: AppType;
  initialImageWidth?: number;
  multiple?: number;
  expandParams?: {
    [x: string]: any
  };
  openLoading?: () => void;
  closeLoading?: () => void;
  onClick?: () => void;
  onSelectSprite?: (sprite?: Sprite) => void;
  onCancelSprite?: () => void;
  onAdd?: () => void;
  onDel?: () => void;

  onHistoryRecord?: () => void;
  onHistoryChange?: () => void;
  onHistoryBack?: () => void;
  onHistoryForward?: () => void;
}
interface ITarget {
  name: string
  sprite: IImageSprite | ITextSprite | null
}

class Pixi {
  public app: Application | null = null
  public productSprite: Sprite | null = null
  public shellSprite: Sprite | null = null
  public shellShowConfigSprite: Sprite | null = null
  public showDesginArea: Sprite | null = null
  public img_container: IImageContainer | null = null
  public operate_icon_container: Container | null = null
  public icon_container: Container | null = null
  public iconContainer_: Container | null = null
  public historyList = null
  public reductionList = null
  public bgSprite: IImageSprite | null = null
  public bgRectangle: IGraphics | null = null
  public expandParams:CoreInitOption | null = null
  public mobile = {
    x: 0,
    y: 0,
  }
  public target: ITarget = {
    name: '',
    sprite: null,
  }
  private option: IOption = {
    width: 0,
    height: 0
  }
  public history: undefined | History
  private shellShowUrl: string = ''
  private eventSelected: boolean = false

  constructor(container: HTMLElement, option: IOption) {
    const app = new PIXI.Application({
      width: option.width,
      height: option.height,
      // antialias: true,
      transparent: true,
      backgroundColor: 0x000000,
      resolution: 3
    })
  
    app.view.style.backgroundColor = 'transparent'
    app.view.id = 'canvasDraw'
    app.view.addEventListener('touchstart', () => {
      option.onClick?.()
    })
    container.appendChild(app.view)
    app.view.style.width = option.width + 'px'
    app.view.style.height = option.height + 'px'
    this.option = option
    if (option.expandParams) {
      this.expandParams = option.expandParams
    }

    this.app = app

    // 图标组
    this.icon_container = new PIXI.Container()
    this.icon_container.name = '素材图标组'
    app.stage.addChild(this.icon_container)
    this.iconContainer_ = this.icon_container

    this.img_container = new PIXI.Container()
    this.img_container.name = '素材图片组'
    this.img_container.visible = true
    app.stage.addChild(this.img_container)

    this.operate_icon_container = new PIXI.Container()
    this.operate_icon_container.name = '操作图标组'
    this.operate_icon_container.visible = false
    app.stage.addChild(this.operate_icon_container)

    const webgl = document.createElement('canvas').getContext('webgl')
    // @ts-ignore
    console.log('最大支持纹理数 = ', webgl.getParameter(webgl.MAX_TEXTURE_SIZE))

    initLoad.call(this)
    // 初始化事件
    initEvent.call(this)

    this.history = new History({
      getData: (type: IHistoryItemType, list: any[]) => {
        return {
          type,
          changeList: list.map((c) => {
            const sprite = c.target
            let index
            try {
              index = sprite.parent ? sprite.parent.getChildIndex(sprite) : undefined
            } catch(err) {

            }
            return {
              value: getSpriteTypeValue(sprite.type, sprite),
              fontStyle: sprite.type === 'text' ? getTextFontStyle(sprite) as any : null,
              bgType: null,

              type: sprite.type,
              // index: sprite.parent ? sprite.parent.getChildIndex(sprite) : undefined,
              // index: this.img_container?.getChildIndex(sprite) || undefined,
              index,
              target: sprite,
              x: sprite.x,
              y: sprite.y,
              width: sprite.width,
              height: sprite.height,
              scale: [sprite.scale.x, sprite.scale.y],
              rotation: sprite.rotation,
              filters: [...(sprite.filters || [])],
            }
          })
        }
      },
      onAdd: (t: IChangeItem) => {
        this.img_container?.addChild(t.target)
      },
      onDel: (t: IChangeItem) => {
        this.hideOperateIconContainer()
        this.resetEventTarget()
        const item = this.img_container?.children.find((c: any) => c.uuid === t.target.uuid)
        item && this.img_container?.removeChild(item)
      },
      onUpdate: (t: IChangeItem) => {
        if (t.target.type === 'text' && t.fontStyle) {
          Object.keys(t.fontStyle).forEach((k: string) => {
            // @ts-ignore
            t.target.style[k] = t.fontStyle[k]
          })
          this.updateTextSprite(t.target, t.value, t.fontStyle as any)
        }
        // t.target.parent.getChildIndex
        t.target.x = t.x
        t.target.y = t.y
        t.target.width = t.width
        t.target.height = t.height
        t.target.rotation = t.rotation
        t.scale && t.target.scale.set(...t.scale)
        t.index && t.target.parent.setChildIndex(t.target, t.index)
        if (t.filters) {
          t.target.filters = t.filters as any
        }
        // console.log(t.target.uuid, this.target.sprite?.uuid)
        if (t.target.uuid !== this.target.sprite?.uuid) {
          this.hideOperateIconContainer()
          this.resetEventTarget()
          this.option.onCancelSprite?.()
        } else {
          this.showButton(t.target)
        }
      },
    })
  }
  // history 
  public historyBack() {
    this.history?.revoke()
    this.option.onHistoryBack?.()
    this.option.onHistoryChange?.()
  }
  public historyForward() {
    this.history?.restore()
    this.option.onHistoryForward?.()
    this.option.onHistoryChange?.()
  }
  public historyRecord(type: IHistoryItemType, list: any[]) {
    this.history?.record(type, list)
    this.option.onHistoryChange?.()
  }
  public historyStart(type: IHistoryItemType, list: any[]) {
    this.history?.recordStart(type, list)
  }
  public historyEnd() {
    this.history?.recordEnd()
    this.option.onHistoryChange?.()
  }


  // 产品加载回调
  private onProductLoaded(callback: () => void) {
    if (!this.productSprite) {
      return
    }
    const { width, height } = this.productSprite
    // 撑满宽
    if (width > height) {
      const scale = this.option.width / width
      let y = (this.option.height / 2) - (height * scale / 2)
      this.productSprite.scale.set(scale)
      this.productSprite.position.set(0, y)
    } else {
      
      // 撑满高
      const scale = this.option.height / height
      let x = (this.option.width / 2) - (width * scale / 2)
      this.productSprite.scale.set(scale)
      this.productSprite.position.set(x, 0)
    }
    callback()
    // this.productSprite.visible = true
  }
  
  public addProductImage(urlValue: string) {
    return new Promise(async (resolve, reject) => {
      if (!urlValue) {
        reject('loading failed')
        return
      }
      try {
        let url = getIntegrateUrl(
          urlValue,
          this.autoCountWidTemp(window.innerWidth)
        )
        const base64: string = await convertImgToBase64(url)
        const sprite = PIXI.Sprite.from(base64)
        // sprite.visible = false
        this.productSprite = sprite
  
        // 复用加载
        if (sprite.texture.baseTexture.valid) this.onProductLoaded(() => resolve(true))
        sprite.texture.baseTexture.on('loaded', () => this.onProductLoaded(() => resolve(true)))
        this.app && this.app.stage.addChildAt(sprite, 0);
      } catch(err) {
        console.log(err)
        reject('loading failed')
      }
    })
  }

  private async onShellLoaded ({ isLoadDesignArea }: any) {
    if (!this.shellSprite || !this.productSprite) {
      return
    }
    if (!this.shellShowUrl) {
      return
    }
    if (this.shellShowConfigSprite) {
      this.app?.stage.removeChild(this.shellShowConfigSprite)
    }
    this.shellSprite.width = this.productSprite.width
    this.shellSprite.height = this.productSprite.height
    this.shellSprite.x = this.productSprite.x
    this.shellSprite.y = this.productSprite.y

    try {
      let url = getIntegrateUrl(
        this.shellShowUrl,
        this.autoCountWidTemp(this.productSprite?.width || 1)
      )
      const base64: string = await convertImgToBase64(url)
      const sprite = PIXI.Sprite.from(base64)
      this.shellShowConfigSprite = sprite
      const loaded = () => {
        console.log('ok show url  ')
      }
      sprite.width = this.productSprite.width
      sprite.height = this.productSprite.height
      sprite.position.set(this.productSprite.x, this.productSprite.y)
      if (sprite.texture.baseTexture.valid) loaded()
      sprite.texture.baseTexture.on('loaded', () => loaded())
      this.app?.stage.addChildAt(sprite, 1)
      if (this.img_container) {
        this.img_container.mask = sprite
      }
      console.log('this.isLoadDesignArea', isLoadDesignArea)
      isLoadDesignArea && this.generateDeisgnHigh(sprite);
    } catch(err) {
      console.log(err)
    }
  }
  public clearShell() {
    const { shellSprite, shellShowConfigSprite } = this
    shellSprite && this.app?.stage.removeChild(shellSprite)
    shellShowConfigSprite && this.app?.stage.removeChild(shellShowConfigSprite)
    this.shellSprite = null
    this.shellShowUrl = ''
  }
  public async addShellImage(urlValue: string, showUrl: string, isLoadDesignArea: boolean = true) {
    if (!urlValue) {
      return
    }
    return new Promise(async (resolve, reject) => {
      try {
        // clear prev shell sprite
        console.log(this.productSprite?.width)
        this.clearShell()
        let url = getIntegrateUrl(
          urlValue,
          this.autoCountWidTemp(this.productSprite?.width || window.innerWidth)
        )
        const base64: string = await convertImgToBase64(url)
        const sprite = PIXI.Sprite.from(base64)
        this.shellSprite = sprite
        this.shellShowUrl = showUrl
  
        // 复用加载
        if (sprite.texture.baseTexture.valid) {
          await this.onShellLoaded({ isLoadDesignArea })
          resolve(null);
        }
        sprite.texture.baseTexture.on('loaded', async () => {
          await this.onShellLoaded({ isLoadDesignArea });
          resolve(null);
        })
        this.app && this.app.stage.addChildAt(sprite, 2);
      } catch(err) {
        console.log(err)
      }
    })
  }
  
  public async updateTextWeight(sprite: ITextSprite, weight: any) {
    if (!sprite) {
      return
    }
    sprite.style.fontWeight = String(weight) as PIXI.TextStyleFontWeight
  }
  public async updateTextStroke(sprite: ITextSprite, style: IConfigFontStyle) {
    if (!sprite) {
      return
    }
    sprite.style.stroke = style.stroke
    sprite.style.strokeThickness = style.strokeThickness
  }
  public async updateTextSprite(sprite: Text, text: string, style: IConfigFontStyle) {
    if (!sprite) {
      return
    }
    sprite.text = text
    if (style.fill) {
      console.log(Number('0x' + style.fill.slice(1)))
      sprite.style.fill = Number('0x' + style.fill.slice(1))
    } else {
      sprite.style.fill = Number('0x101112')
    }
    if (style.fontFamily) {
      sprite.style.fontFamily = style.fontFamily
    } else {
      sprite.style.fontFamily = ''
    }

    if (style.fontWeight) {
      this.updateTextWeight(sprite, style.fontWeight)
    }
    if (style.stroke) {
      sprite.style.stroke = style.stroke || Number('0xffffff')
      sprite.style.strokeThickness = style.strokeThickness || 0
    }
    requestAnimationFrame(() => {
      this.showButton(sprite)
    })
  }
  async addText(text: string, style?: TextStyle | null, align?: IAlignOptions) {
    const fontStyle = new PIXI.TextStyle({
      fontSize: 24 * 0.85,
      lineHeight: 24,
      align: align,
      fill: '#101112',
      fontWeight: '400',
      stroke: '#ffffff',
      fontFamily: '思源黑體',
      // fontFamily: `Inter, Noto Color Emoji, system-ui, Avenir, Helvetica, Arial, sans-serif`,
      // fontFamily: style?.fontFamily || [],
      // fontWeight: style?.fontWeight || undefined,
    });
    let textSprite: ITextSprite = new PIXI.Text(text, fontStyle);
    textSprite.type = "text"
    textSprite.uuid = generateUUID();
    textSprite.position.set(this.option.width / 2, this.option.height / 2)
    textSprite.anchor.set(0.5);
    textSprite.scale.set(1);
    textSprite.interactive = true;
    textSprite.on('pointerdown', () => {
      this.eventSelected = true
      // @ts-ignore
      this.selectSprite(textSprite)
    })
    

    this.img_container?.addChild(textSprite)
    this.option.onAdd?.()

    return textSprite
  }
  public addImage(urlValue: string, option?: IAddImageOption): Promise<IImageSprite | undefined> {
    if (!urlValue) {
      return Promise.resolve(undefined)
    }
    
    const { img_container } = this
    return new Promise(async (resolve, reject) => {
      try {
        let productWid = this.productSprite?.width || 1
        let initialImageWidth = option?.width || this.option.initialImageWidth || productWid / 2
        // console.log(initialImageWidth, '-initialImageWidth')
        let url = getIntegrateUrl(
          urlValue,
          option?.imgTemp ? this.autoCountWidTemp(initialImageWidth, option?.imgTemp) : this.autoCountWidTemp(initialImageWidth)
        )
        this.option.openLoading?.()
        const sprite: IImageSprite = new PIXI.Sprite();
        this.img_container?.addChild(sprite);
        const base64 = await convertImgToBase64(url);
        sprite.texture = PIXI.Texture.from(base64);
        // const imageSprite: IImageSprite = PIXI.Sprite.from(base64);
        sprite.type = "image"
        sprite.uuid = generateUUID();
        sprite.urlValue = urlValue
        sprite.url = url
        const loaded = async () => {
          if (!this.productSprite) {
            return
          }
          sprite.originSize = {
            width: sprite.width,
            height: sprite.height
          }
          if (option?.onLoaded) {
            option?.onLoaded(sprite)
          } else {
            const scale = (initialImageWidth) / sprite.width
            sprite.scale.set(scale)
            sprite.x = this.option.width / 2
            sprite.y = this.option.height / 2
          }
          
          this.option.closeLoading && setTimeout(() => {
            this.option.closeLoading?.()
          }, 1000)
          resolve(sprite)
        }

        // img_container && img_container.addChild(sprite);
        option?.onCreated?.(sprite)
        this.option.onAdd?.()
        sprite.on('pointerdown', () => {
          this.eventSelected = true
          // @ts-ignore
          this.selectSprite(sprite)
        })
        // sprite.on('pointerup', (event) => {})
        sprite.anchor.set(0.5)
        sprite.interactive = true
        sprite.texture.baseTexture.on('loaded', loaded)
        if (sprite && sprite.texture.baseTexture.valid) {
          loaded();
        }
        return sprite
      } catch(err) {
        console.log(err)
      }
    })
  }
  private resetBgAll () {
    this.bgSprite && this.img_container?.removeChild(this.bgSprite)
    this.bgRectangle && this.img_container?.removeChild(this.bgRectangle)
  }
  private addBgImage(urlValue: string, option?: IAddImageOption): Promise<IImageSprite | undefined> {
    if (!urlValue || !this.productSprite) {
      return Promise.resolve(undefined)
    }
    this.resetBgAll()
    // @ts-ignore
    const { img_container } = this
    let { width: productWid } = this.productSprite
    let url = getIntegrateUrl(
      urlValue,
      this.autoCountWidTemp(productWid / 2, option?.imgTemp)
    )
    return new Promise(async (resolve, reject) => {
      try {
        this.option.openLoading?.()
        const base64 = await convertImgToBase64(url)
        // @ts-ignore
        const sprite: IImageSprite = PIXI.Sprite.from(base64)
        sprite.type = 'image'
        sprite.uuid = generateUUID();
        this.bgSprite = sprite
        this.bgRectangle = null
        const loaded = () => {
          if (!this.productSprite) {
            return
          }
          let { width: productWid, height: productHei } = this.productSprite
          let scale: number = 1
          if (productWid > productHei) {
            scale = (this.productSprite.width) / sprite.width
          } else {
            scale = (this.productSprite.height) / sprite.height
          }
          sprite.scale.set(scale)
          sprite.x = this.option.width / 2
          sprite.y = sprite.height / 2
          // console.log(this.productSprite.y)
          sprite.y = this.productSprite.y + (this.productSprite.height / 2)
  
          this.option.closeLoading && setTimeout(() => {
            this.option.closeLoading?.()
          }, 1000)
          resolve(sprite)
        }
        img_container && img_container.addChildAt(sprite, 0);
        option?.onCreated?.(sprite)
        sprite.on('pointerdown', (event) => {
          this.eventSelected = true
          // @ts-ignore
          this.selectSprite(sprite)
        })
        sprite.anchor.set(0.5)
        sprite.texture.baseTexture.on('loaded', loaded)
        if (sprite && sprite.texture.baseTexture.valid) {
          loaded();
        }
      } catch(err) {
        console.log(err)
      }
    })
  }
  private addBgColor(color: string) {
    if (!this.productSprite) {
      return
    }
    this.resetBgAll()
    const rectangle: IGraphics = new PIXI.Graphics()
    rectangle.type = 'color'
    rectangle.uuid = generateUUID()
    // @ts-ignore
    rectangle.color = color
    this.bgRectangle = rectangle
    this.bgSprite = null
    const { width, height, y } = this.productSprite
    rectangle.beginFill(Number('0x' + color));
    rectangle.drawRect(this.option.width / 2 - (width / 2), y, width, height);
    rectangle.endFill()
    this.img_container?.addChildAt(rectangle, 0)
  }
  public async addBg(type: 'color' | 'image', url: string) {
    if (type === 'image' && typeof url === 'string') {
      this.addBgImage(url, {
        onCreated: (sprite: IImageSprite) => {
          this.historyRecord('add', [sprite])
        }
      })
    }
    // type === 'image' && typeof url === 'string' && this.addBgImage(url)
    type === 'color' && this.addBgColor(url)
  }
  public showButton(sprite: Sprite) {
    if (!sprite) {
      return
    }
    // @ts-ignore
    const { operate_icon, operate_icon_container } = this
    const margin = 15;
    if (operate_icon_container) {
      operate_icon_container.visible = true;
      operate_icon_container.position.set(sprite.x, sprite.y);
      operate_icon_container.rotation = sprite.rotation;
    }
  
    //显示操作icon
    operate_icon.delete.position.set(0, 0 - sprite.height / 2 - margin);
    operate_icon.rotate.position.set(0 + sprite.width / 2 + margin - 5, -sprite.height / 2 - margin + 5);
    operate_icon.scale_arrow.position.set(0 + sprite.width / 2 + margin - 5, sprite.height / 2 + margin - 5);
  
    //划线
    operate_icon.line.clear();
    operate_icon.line.lineStyle(1, 0xeae8ec, 1);
    // a
    operate_icon.line.moveTo(0 - sprite.width / 2, 0 - sprite.height / 2);
    operate_icon.line.lineTo(0 + sprite.width / 2, 0 - sprite.height / 2);
    operate_icon.line.lineTo(0 + sprite.width / 2, 0 + sprite.height / 2);
    operate_icon.line.lineTo(0 - sprite.width / 2, 0 + sprite.height / 2);
    operate_icon.line.closePath();
  }
  public hideOperateIconContainer() {
    const { operate_icon_container } = this
    if (operate_icon_container) {
      operate_icon_container.visible = false;
    }
  }
  public selectSprite(target: string | ITextSprite | IImageSprite) {
    let sprite = this.getSprite(target)
    if (sprite) {
      this.target = {
        name: '',
        sprite
      }
      this.showButton(sprite)
      this.option.onSelectSprite?.(sprite)
    }
  }
  private resetEventTarget() {
    this.target = {
      name: '',
      sprite: null
    }
    this.option.onCancelSprite?.()
  }

  public setIndex(sprite: Sprite | Text, type: ISpriteIndexType) {
    if (!sprite) {
      return
    }
    const parent = sprite.parent
    this.historyStart('update', [sprite])
    
    switch(type) {
      case 'top':
        parent.removeChild(sprite)
        parent.addChild(sprite)
        break
        case 'bottom':
        parent.removeChild(sprite)
        parent.addChildAt(sprite, 0)
        break
    }
    this.historyEnd()
  }

  public mirror (sprite: IImageSprite, type: MirrorType) {
    if (!sprite) {
      return
    }
    this.historyStart('update', [sprite])
    switch(type) {
      case 'x':
        sprite.scale.x *= -1
        sprite.mirrorX = sprite.scale.x < 0
        break
        case 'y':
          sprite.scale.y *= -1
          sprite.mirrorY = sprite.scale.y < 0
        break
    }
    this.historyEnd()
  }
  clearStage() {
    const app = this.app
    const stage = app?.stage
    if (!app) {
      return;
    }
    if (!stage) {
      return;
    }
    stage.removeChildren().forEach(child => {
      if (child.destroy) {
        child.destroy({ children: true, texture: true, baseTexture: true });
      }
    });
    PIXI.utils.clearTextureCache();
    PIXI.Loader.shared.reset();

    app.ticker.stop();
    app.ticker.destroy();

    app.stage.removeAllListeners();

    app.renderer.destroy(true);
  }

  public reset() {
    let { sprite } = this.target
    const removeTypes = ['image', 'text']
    this.img_container?.children.forEach(t => {
      if (removeTypes.includes((t as IImageSprite | ITextSprite).type || '')) {
        t.destroy()
        this.img_container?.removeChild(t)
      }
    })
    // this.img_container?.removeChildren()
    this.history?.resetAll()
    this.option.onHistoryChange?.()
    sprite && this.option.onCancelSprite?.()
    this.hideOperateIconContainer()
  }

  private async downLoadFontSync (name: string, url: string) {
    // @ts-ignore
    const font = new FontFace(name, `url(${url})`)
    try {
      const loadedFontFace = await font.load()
      // @ts-ignore
      document.fonts.add(loadedFontFace)

      console.log(`loaded success font: ${name}`);
      const textSpriteList = this.img_container?.children.filter(t => (t as any).type === 'text') as ITextSprite[];
      textSpriteList?.forEach((t: ITextSprite) => {
        if (t.style.fontFamily === name) {
          let text = t.text;
          t.text = '';
          t.style.fontFamily = name;
          t.text = text;
        }
      })
    } catch(err) {
      console.log(err)
      console.log('字體加載失敗')
    }
  }

  public async copyProperty(sprite: IChildSprite, copySprite: IImageSprite | ITextSprite, deviceScale: number) {
    // console.log(sprite, copySprite)
    if (!this.productSprite) {
      return
    }
    if (copySprite.type === 'image') {
      copySprite.type = "image"
      copySprite.width = sprite.width * deviceScale
      copySprite.height = sprite.height * deviceScale
      copySprite.rotation = sprite.rotation
      copySprite.on('pointerdown', () => {
        this.eventSelected = true
        // @ts-ignore
        this.selectSprite(copySprite)
      })
      copySprite.anchor.set(0.5)
    } else if (copySprite.type === 'text') {
      copySprite.anchor.set(0.5)
      copySprite.style.fontSize = Number(sprite.fontStyle?.fontSize || 12) * deviceScale
      copySprite.style.lineHeight = Number(sprite.fontStyle?.lineHeight) * deviceScale
      copySprite.style.fill = sprite.fontStyle?.fill
      copySprite.style.fontWeight = sprite.fontStyle?.fontWeight || '400'
      copySprite.style.stroke = sprite.fontStyle?.stroke
      copySprite.style.strokeThickness = sprite.fontStyle?.strokeThickness
      copySprite.rotation = sprite.rotation
      const fontFamily = sprite.fontStyle?.fontFamily
      if (fontFamily) {
        const findFont = FontList.find(t => t.name === fontFamily)
        if (findFont) {
          await this.downLoadFontSync(findFont.name, findFont.url);
          copySprite.style.fontFamily = findFont.name;
        }
      }
    }
    copySprite.position.set((sprite.x * deviceScale) + this.productSprite.x, (sprite.y * deviceScale) + this.productSprite.y)
  }

  public copy(sprite: IImageSprite | ITextSprite, index?: number) {
    if (!sprite) {
      return
    }
    let copySprite: IImageSprite | ITextSprite | undefined
    if (sprite.type === 'image') {
      copySprite = PIXI.Sprite.from(sprite.texture.baseTexture.cacheId)
      copySprite.type = "image"
      copySprite.scale.set(sprite.scale.x, sprite.scale.y)
      copySprite.position.set(sprite.position.x + 10, sprite.position.y + 10)
      copySprite.cartoon = sprite.cartoon
      copySprite.cutout = sprite.cutout
      copySprite.urlValue = sprite.urlValue
      copySprite.on('pointerdown', () => {
        this.eventSelected = true
        // @ts-ignore
        this.selectSprite(copySprite)
      })
      copySprite.anchor.set(0.5)
      copySprite.interactive = true
      this.img_container?.addChild(copySprite)
    }
    if (sprite.type === 'text') {
      const fontStyle = new PIXI.TextStyle({
        fontSize: sprite.style.fontSize,
        lineHeight: sprite.style.lineHeight,
        // align: align,
        fill: sprite.style.fill
      });
      let copySprite: ITextSprite = new PIXI.Text(sprite.text, fontStyle);
      copySprite.type = "text"
      copySprite.uuid = generateUUID();
      copySprite.fontUrl = '';
      copySprite.position.set(sprite.position.x + 10, sprite.position.y + 10)
      copySprite.anchor.set(0.5);
      copySprite.scale.set(1);
      copySprite.interactive = true;
      copySprite.on('pointerdown', () => {
        this.eventSelected = true
        // @ts-ignore
        this.selectSprite(copySprite)
      })
      this.img_container?.addChild(copySprite)
    }
    this.historyRecord('add', [copySprite])
  }

  public getSprite(target: string | ITextSprite | IImageSprite): ITextSprite | IImageSprite {
    let sprite: ITextSprite | IImageSprite | null = null
    if (typeof target === 'string') {
      sprite = this.img_container?.children.find((t: any) => t.uuid === target) as ITextSprite | IImageSprite
    } else {
      sprite = target
    }
    return sprite
  }
  private ifSpriteSelected(sprite: ITextSprite | IImageSprite) {
    if (sprite === this.target.sprite) {
      this.hideOperateIconContainer()
      this.resetEventTarget()
    }
  }

  public toggleShowSprite(target: string | ITextSprite | IImageSprite): boolean | undefined {
    let sprite = this.getSprite(target)
    if (sprite) {
      const visibleValue = !sprite.visible
      sprite.visible = visibleValue
      this.ifSpriteSelected(sprite)
      return visibleValue
    }
  }

  public toggleLockSprite(target: string | ITextSprite | IImageSprite): boolean | undefined {
    let sprite = this.getSprite(target)
    if (sprite) {
      const visibleValue = !sprite.interactive
      sprite.interactive = visibleValue
      this.ifSpriteSelected(sprite)
      return visibleValue
    }
  }

  public remove(target: string | ITextSprite | IImageSprite) {
    let sprite = this.getSprite(target)
    if (sprite) {
      this.ifSpriteSelected(sprite)
      sprite.parent.removeChild(sprite)
    }
  }

  public getParams(): IParams | undefined {
    if (!this.productSprite) {
      return
    }
    const params: IParams = {
      id: this.option.id,
      basic: {
        width: Math.round(this.option.width),
        height: Math.round(this.option.height),
        productWidth: Math.round(this.productSprite.width),
        productHeight: Math.round(this.productSprite.height),
      },
      children: [],
      // sideName: '',
      expandParams: this.expandParams,
    }
    if (this.img_container) {
      let { x, y } = this.productSprite
      // @ts-ignore
      params.children = this.img_container.children.map((sprite: ITextSprite | IImageSprite | IGraphics, index: number): IChildSprite => {
        let fontStyle: any
        if (sprite.type === 'text') {
          fontStyle = getTextFontStyle(sprite)
        }
        
        if (this.bgRectangle?.uuid && sprite.uuid === this.bgRectangle?.uuid) {
          return {
            type: 'color',
            value: getSpriteTypeValue(sprite.type, sprite),
            x: 0,
            y: 0,
            index,
            bgType: 'color',
            width: 0,
            height: 0,
            rotation: 0,
            fontStyle: null,
            scale: null,
          }
        } else {
          return {
            type: sprite.type,
            value: sprite.type === 'image' ? (sprite.urlValue || '') : (sprite.type === 'text' ? sprite.text : ''),
            x: sprite.x - x,
            y: sprite.y - y,
            index,
            rotation: sprite.rotation,
            bgType: sprite === this.bgSprite ? 'image' : null,
            width: sprite.width,
            height: sprite.height,
            scale: [sprite.scale.x, sprite.scale.y],
            fontStyle,
            cartoon: sprite.type === 'image' ? sprite.cartoon : false,
            cutout: sprite.type === 'image' ? sprite.cutout : false,
            cutoutType: sprite.type === 'image' ? sprite.cutoutType : undefined,
            imageFill: sprite.type === 'image' && sprite.imageFill ? {
              url: sprite.imageFill.url,
              x: sprite.imageFill.x,
              y: sprite.imageFill.y
            } : undefined,
            filters: sprite.type === 'image' ? sprite.filters?.map((t: PIXI.Filter & {name?: string}) => ({
              name: t.name || '',
              value: ''
            })) : []
          }
        }
      })
    }
    // params.children = JSON.stringify(params.children)
    return params
  }

  public generateDeisgnImage() {
    if (!this.productSprite) {
      return
    }
    
    if (!this.app) {
      return
    }
    let { multiple } = this.option
    let scaleFactor = multiple ? (240 * multiple) / this.app?.stage.width : 1
    console.log('scaleFactor', scaleFactor)
    const app = this.app
    if (this.productSprite && this.shellSprite) {
      this.productSprite.visible = false
      this.shellSprite.visible = false
    }
    if (this.operate_icon_container) {
      this.operate_icon_container.visible = false
    }
    app.stage.scale.set(scaleFactor)
    app.stage.x -= (this.option.width * scaleFactor / 2) - (this.productSprite.width * scaleFactor / 2)
    app.stage.y -= (this.option.height * scaleFactor / 2) - (this.productSprite.height * scaleFactor / 2)
    const renderTexture = PIXI.RenderTexture.create({
      width: this.productSprite.width * scaleFactor,
      height: this.productSprite.height * scaleFactor,
      resolution: 3,
    });
    
    // 渲染舞台内容到RenderTexture
    app.renderer.render(app.stage, renderTexture as any);
    // 提取图像
    const image = app.renderer.plugins.extract.base64(renderTexture, 'image/png', 2);
    app?.stage.scale.set(1)
    app.stage.x = 0
    app.stage.y = 0
    if (this.productSprite && this.shellSprite) {
      this.productSprite.visible = true
      this.shellSprite.visible = true
    }
    return image
  }


  public generateDeisgnImageFromGenerate() {
    if (!this.productSprite) {
      return
    }
    if (!this.app) {
      return
    }
    let { multiple } = this.option
    console.log('multiple', multiple)
    // let scaleFactor = multiple ? (240 * multiple) / this.app?.stage.width : 1
    let scaleFactor = 1
    const app = this.app
    app.stage.scale.set(scaleFactor)
    app.stage.x -= (this.option.width * scaleFactor / 2) - (this.productSprite.width * scaleFactor / 2)
    app.stage.y -= (this.option.height * scaleFactor / 2) - (this.productSprite.height * scaleFactor / 2)
    const renderTexture = PIXI.RenderTexture.create({
      width: this.productSprite.width * scaleFactor,
      height: this.productSprite.height * scaleFactor,
      resolution: 3,
    });
    
    // 渲染舞台内容到RenderTexture
    app.renderer.render(app.stage, renderTexture as any);
    // 提取图像
    const image = app.renderer.plugins.extract.base64(renderTexture, 'image/png', 2);
    app?.stage.scale.set(1)
    app.stage.x = 0
    app.stage.y = 0
    return image
  }
  // public generateDeisgnImage() {
  //   let base = 2
  //   let width = this.option.width * base
  //   let height = this.option.height * base
  //   let renderTexture = PIXI.RenderTexture.create(width, height)
  //   if (!this.app) {
  //     return
  //   }
  //   if (this.showDesginArea) {
  //     this.showDesginArea.visible = false
  //   }
  //   if (this.productSprite && this.shellSprite) {
  //     this.productSprite.visible = false
  //     this.shellSprite.visible = false
  //   }
  //   if (this.operate_icon_container) {
  //     this.operate_icon_container.visible = false
  //   }
  //   this.app?.stage.scale.set(base)
  //   // @ts-ignore
  //   this.app.renderer.render(this.app.stage, renderTexture)
  //   let image = this.app.renderer.plugins.extract.base64(renderTexture, 'image/png', 1)
  //   this.app?.stage.scale.set(1)
  //   if (this.productSprite && this.shellSprite) {
  //     this.productSprite.visible = true
  //     this.shellSprite.visible = true
  //   }
  //   if (this.showDesginArea) {
  //     this.showDesginArea.visible = true
  //   }
  //   return image
  // }
  public generateEffectImage() {
    console.log(this.app?.stage.width, this.app?.stage.height, this.app?.stage.x, this.app?.stage.y)
    // return
    if (!this.productSprite) {
      return
    }
    const app = this.app
    if (!app) {
      return
    }
    let scaleFactor = 1
    if (!this.app) {
      return
    }
    
    if (this.operate_icon_container) {
      this.operate_icon_container.visible = false
    }
    app.stage.scale.set(scaleFactor)
    app.stage.x -= (this.option.width * scaleFactor / 2) - (this.productSprite.width * scaleFactor / 2)
    app.stage.y -= (this.option.height * scaleFactor / 2) - (this.productSprite.height * scaleFactor / 2)
    const renderTexture = PIXI.RenderTexture.create({
      width: this.productSprite.width * scaleFactor,
      height: this.productSprite.height * scaleFactor,
      resolution: scaleFactor,
    });
    
    // 渲染舞台内容到RenderTexture
    app.renderer.render(app.stage, renderTexture as any);
    // 提取图像
    const image = app.renderer.plugins.extract.base64(renderTexture, 'image/png', 1);
    app?.stage.scale.set(1)
    app.stage.x = 0
    app.stage.y = 0
    return image
  }
  hiddenShowDesignArea() {
    if (this.showDesginArea) {
      this.showDesginArea.visible = false
    }
  }
  openShowDesignArea() {
    if (this.showDesginArea) {
      this.showDesginArea.visible = true
    }
  }
  // public generateEffectImage() {
  //   let base = 2
  //   let width = this.option.width * base
  //   let height = this.option.height * base
  //   let renderTexture = PIXI.RenderTexture.create(width, height)
  //   if (!this.app) {
  //     return
  //   }
  //   if (this.showDesginArea) {
  //     this.showDesginArea.visible = false
  //   }
  //   if (this.operate_icon_container) {
  //     this.operate_icon_container.visible = false
  //   }
  //   this.app?.stage.scale.set(base)
  //   // @ts-ignore
  //   this.app.renderer.render(this.app.stage, renderTexture)
  //   let image = this.app.renderer.plugins.extract.base64(renderTexture, 'image/png', 1)
  //   this.app?.stage.scale.set(1)
  //   if (this.showDesginArea) {
  //     this.showDesginArea.visible = true
  //   }
  //   return image
  // }

  public loadDesign(data: any, hasCover?: boolean, completedFn?: () => void) {
    if (!this.productSprite) {
      return
    }
    if (hasCover) {
      this.reset()
    }
    let history: any = {
      type: 'add',
      changeList: []
    }
    let deviceScale = this.productSprite.width / data.basic.productWidth
    // console.log(data.children, '--data.children')
    data.children.forEach(async (c: IChildSprite, index: number) => {
      if (c.bgType === 'color') {
        this.addBgColor(c.value)
        return
      }
      switch(c.type) {
        case 'text':
          let textSprite: ITextSprite = await this.addText(c.value)
          await this.copyProperty(c, textSprite, deviceScale)
          history.changeList.push(textSprite)
          break
        case 'image':
          if (c.bgType === 'image') {
            let imageSprite: IImageSprite | undefined = await this.addBgImage(c.value, {
              onCreated: (sprite: IImageSprite) => {
                history.changeList.push(sprite)
              }
            })
            imageSprite && await this.copyProperty(c, imageSprite, deviceScale)
          } else {
            let imageSprite: IImageSprite | undefined = await this.addImage(c.value, {
              width: c.width,
              imgTemp: getUseImgTemp(c),
              onCreated: (sprite: IImageSprite) => {
                history.changeList.push(sprite)
              }
            })
            imageSprite && await this.copyProperty(c, imageSprite, deviceScale)
            imageSprite && c.imageFill && this.fillImageSpite(imageSprite, c.imageFill.url)
          }
          break
      }
      if (index === data.children.length - 1) {
        this.historyRecord(history.type, history.changeList)
        completedFn?.()
      }
    })
  }

  public hasBgSprite(sprite: IImageSprite | IGraphics) {
    if (sprite.uuid === this.bgSprite?.uuid) {
      return 'image'
    }
    if (sprite.uuid === this.bgRectangle?.uuid) {
      return 'color'
    }
  }

  public fillImageSpite(sprite: IImageSprite, url?: string) {
    return new Promise(async (resolve) => {
      if (!sprite) {
        throw new Error('fill sprite is a not found')
      }
      if (!url) {
        sprite.removeChildren();
        return
      }
      const findFilter = this.findSpriteFilter(sprite, 'fillColor')
      if (findFilter) {
        this.delSpriteFilter(sprite, [findFilter.name])
      }
      const imgBase64 = await convertImgToBase64(url)
      // const imgSprite: PIXI.Sprite & {hasImageFill?: boolean} = PIXI.Sprite.from(imgBase64)
      const imgSprite: IImageSprite = PIXI.Sprite.from(imgBase64)
      imgSprite.anchor.set(0.5)
      imgSprite.hasImageFill = true
      imgSprite.imageFillUrl = url
      sprite.hasImageFill = true
      sprite.imageFillUrl = url
      const imgSpriteOnload = () => {
        let scaleWid = cloneSprite.width / imgSprite.width
        let scaleHei = cloneSprite.height / imgSprite.height
        imgSprite.scale.set(Math.max(scaleWid, scaleHei))
        sprite.imageFill = {
          url,
          x: 0,
          y: 0,
        }
        resolve(sprite)
      }
      // @ts-ignore
      const cloneSprite: PIXI.Sprite & {hasImageFill: boolean} = PIXI.Sprite.from(sprite.texture.baseTexture.resource.url)
      cloneSprite.anchor.set(0.5)
      cloneSprite.position.set(0, 0)
      cloneSprite.scale.set(1)
      cloneSprite.hasImageFill = true
      sprite.addChild(cloneSprite)
      sprite.addChild(imgSprite)
      imgSprite.mask = cloneSprite
      
      let texture = generateTexture(cloneSprite)
      if (texture) {
        cloneSprite.texture = texture
      }
      imgSprite.texture.baseTexture.on('loaded', imgSpriteOnload)
      imgSprite.texture.baseTexture.valid && imgSpriteOnload()
    })
  }
  public resetImageFill(sprite: IImageSprite | ITextSprite) {
    if (sprite.children.length === 0) {
      return
    }
    const findFillList = sprite.children.filter((s: PIXI.DisplayObject & SpriteChildItem) => s.hasImageFill)
    findFillList.forEach(f => {
      sprite.removeChild(f)
    })
    if (sprite.isMask) {
      sprite.mask = null
    }
  }

  public fillColorSprite(sprite: IImageSprite | ITextSprite, color: string | undefined) {
    if (!color) {
      this.historyRecord('update', [sprite])
      sprite.filters = []
      sprite.fillColor = ''
      return
    }
    this.historyRecord('update', [sprite])
    sprite.fillColor = color
    const overlay: ColorOverlayFilter & {name?: 'fillColor'} = new ColorOverlayFilter(Number('0x' + color.slice(1)) , 1)
    overlay.name = 'fillColor'
    sprite.filters = [overlay];
  }
  public findSpriteFilter(sprite: IImageSprite | ITextSprite, name: string): PIXI.Filter & {name: string} | undefined {
    if (!sprite.filters?.length) {
      return
    }
    for (let index = 0; index < sprite.filters.length; index++) {
      const element = sprite.filters[index] as PIXI.Filter & {name: string};
      if (element.name === name) {
        return element
      }
    }
  }
  public delSpriteFilter(sprite: IImageSprite | ITextSprite, nameArr: string[]) {
    if (!sprite.filters?.length) {
      throw new Error('sprite filters is undefined')
    }
    sprite.filters = sprite.filters.filter((f: PIXI.Filter & {name?: string}) => !nameArr.includes(f.name as string))
  }

  public resetCountImageSize(sprite: IImageSprite) {
    // if (!sprite.url) {
    //   return
    // }
    // let urlSplit = sprite.url.split(':')
    // let urlWidth = Number(urlSplit[urlSplit.length - 1].split('.')[0])
    // console.log(urlWidth, urlSplit, sprite.width)
    // if (urlWidth < sprite.width) {
    // }
    sprite.urlValue && this.setSpriteTexture(sprite, sprite.urlValue)
  }

  public async  setSpriteTexture(sprite: IImageSprite, urlValue: string) {
    if (!sprite) {
      throw new Error('argment sprite is not found')
    }
    if (!urlValue) {
      throw new Error('argument urlValue is undefined')
    }
    let width = sprite.width, height = sprite.height
    let url = getIntegrateUrl(urlValue, `tplv-z8ialgywaa-w-size:${Math.ceil(sprite.width * 1.7)}`)
    await loadImage(url)
    let texture = PIXI.Texture.from(url)
    sprite.urlValue = urlValue
    sprite.url = url
    sprite.texture = texture
    sprite.width = width
    sprite.height = height
    let onload = () => {
      // console.log('ok loaded')
      // this.showButton(sprite)
    }
    sprite.texture.baseTexture.on('loaded', onload)
  }

  public setStageScale() {
    if (this.app) {
      let enlargeWidth = this.app.stage.width * 1.5
      let enlargeHeight = this.app.stage.height * 1.5
      this.animationPlay(
        300,
        { x: -(this.option.width / 2), y: -(this.option.height / 2), w: enlargeWidth, h: enlargeHeight }
      )
    }
  }

  // 
  public setSpriteCartoon(sprite: IImageSprite) {
    if (!sprite) return
    if (!sprite.urlValue) {
      throw Error('error: sprite not url value.')
    }
    let cartoonUrl = 
      sprite.cartoon
      ? getIntegrateUrl(sprite.urlValue, 'tplv-z8ialgywaa-w-size:400')
      : getIntegrateUrl(sprite.urlValue, 'tplv-z8ialgywaa-cartoon-100')
    let texture = PIXI.Texture.from(cartoonUrl)
    let width = sprite.width
    let height = sprite.height
    sprite.texture = texture
    sprite.cartoon = !sprite.cartoon
    
    const loaded = () => {
      // console.log('loaded', width, sprite.width)
      sprite.width = width
      sprite.height = height
    }
    if (sprite.texture.baseTexture.valid) loaded()
    sprite.texture.baseTexture.on('loaded', () => loaded())
  }
  public setSpriteCutout(sprite: IImageSprite, cutoutType: CutoutType) {
    if (!sprite) return
    if (!sprite.urlValue) {
      throw Error('error: sprite not url value.')
    }
    let hasCutout = !sprite.cutout
    // console.log(cutoutType, hasCutout, '--cutoutType')
    let cutoutUrl = 
      sprite.cutout
      ? getIntegrateUrl(sprite.urlValue, 'tplv-z8ialgywaa-w-size:400')
      : getIntegrateUrl(sprite.urlValue, cutoutType === 'people' ? 'tplv-z8ialgywaa-cutout-100' : 'tplv-z8ialgywaa-cutout-stuff')
    let texture = PIXI.Texture.from(cutoutUrl)
    let width = sprite.width
    let height = sprite.height
    sprite.texture = texture
    sprite.cutout = hasCutout

    sprite.cutoutType = hasCutout ? cutoutType : undefined
    
    const loaded = () => {
      // console.log('loaded', width, sprite.width)
      sprite.width = width
      sprite.height = height
    }
    if (sprite.texture.baseTexture.valid) loaded()
    sprite.texture.baseTexture.on('loaded', () => loaded())
  }
  private animationPlay (time: number, { x, y, w, h }: { x: number; y: number; w: number; h: number }) {
    const playTime = time / 16.7
    const playAll = Math.round(playTime)
    let currX = this.app?.stage.x || 0
    let currY = this.app?.stage.y || 0
    let currW = this.app?.stage.width || 0
    let currH = this.app?.stage.height || 0
    let onceW = (w - currW) / playTime, startW = 0
    let onceH = (h - currH) / playTime, startH = 0
    let onceX = (x - currX) / playTime, startX = 0
    let onceY = (y - currY) / playTime, startY = 0
    let i = 0
    // return
    const play = () => {
      startW += onceW
      startH += onceH
      startX += onceX
      startY += onceY
      this.app?.stage.position.set(currX + startX, currY + startY)
      this.app?.stage.scale.set((currW + startW) / currW)
      // updateAreaScaleInfo(currX + startX, currY + startY)
      i++
      if (i === playAll) {
        return
      }
      window.requestAnimationFrame(play)
    }
    window.requestAnimationFrame(play)
  }
  private autoCountWidTemp (wid: number, temp?: string) {
    let template = temp || 'tplv-z8ialgywaa-w-size'
    return `${template}:${Math.ceil(wid + 1000)}`
  }

  public cloneSprite(sprite: PIXI.Sprite): PIXI.Sprite {
    const clone = new PIXI.Sprite(sprite.texture);
    clone.x = sprite.x;
    clone.y = sprite.y;
    clone.scale.set(sprite.scale.x, sprite.scale.y);
    clone.rotation = sprite.rotation;
    clone.alpha = sprite.alpha;
    clone.tint = sprite.tint;
    clone.anchor.set(sprite.anchor.x, sprite.anchor.y);
    // 复制其他属性
    clone.visible = sprite.visible;
    clone.interactive = sprite.interactive;
    clone.buttonMode = sprite.buttonMode;
    clone.filters = sprite.filters ? sprite.filters.slice() : null;
    clone.name = sprite.name;
    clone.width = sprite.width;
    clone.height = sprite.height;

    return clone;
  }

  public async generateDeisgnHigh(deisgnSprite: PIXI.Sprite) {
    this.showDesginArea ? this.img_container?.removeChild(this.showDesginArea) : null
    const sprite = this.cloneSprite(deisgnSprite);
    sprite.filters = [new ColorOverlayFilter(0x0541b8)];
    sprite.alpha = 0.3
    this.showDesginArea = sprite
    this.img_container?.addChildAt(sprite, 0)
  }

  public async setDesginArea (desginAreaUrl: string, cb?: () => void) {
    if (!this.productSprite) {
      return
    }
    if (this.shellShowConfigSprite) {
      this.app?.stage.removeChild(this.shellShowConfigSprite)
    }
    // console.log(desginAreaUrl, '--desginAreaUrl')
    try {
      this.shellShowUrl = desginAreaUrl
      let url = getIntegrateUrl(
        desginAreaUrl,
        this.autoCountWidTemp(this.productSprite?.width || 1)
      )
      const base64: string = await convertImgToBase64(url)
      const sprite = PIXI.Sprite.from(base64)
      this.shellShowConfigSprite = sprite
      const loaded = () => {
        setTimeout(() => {
          cb?.()
        }, 500)
      }
      sprite.width = this.productSprite.width
      sprite.height = this.productSprite.height
      sprite.position.set(this.productSprite.x, this.productSprite.y)
      if (sprite.texture.baseTexture.valid) loaded()
      sprite.texture.baseTexture.on('loaded', () => loaded())
      this.shellShowConfigSprite && this.app?.stage.removeChild(this.shellShowConfigSprite)
      this.app?.stage.addChildAt(sprite, 1)
      if (this.img_container) {
        this.img_container.mask = sprite
      }
      this.generateDeisgnHigh(sprite)
    } catch(err) {
      console.log(err)
    }
  }
}

// const PARAMS_CHILD_VALUE = new Map([
//   ['image', ]
// ])

export default Pixi
