import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, createCommentVNode as _createCommentVNode, isRef as _isRef, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "page absolute w-full h-full flex flex-col web overflow-hidden",
  style: {"font-size":"14px"}
}
const _hoisted_2 = { class: "h-[60px] relative border-b border-slate-200 border-solid" }
const _hoisted_3 = { class: "flex flex-wrap flex-1 relative" }
const _hoisted_4 = { class: "flex-none w-[180px] pt-[12px]" }
const _hoisted_5 = { class: "flex-auto relative mr-[320px] bg-[#eee] justify-center flex" }
const _hoisted_6 = { class: "absolute right-[0px] w-[320px] bg-[#fff] overflow-auto h-full" }
const _hoisted_7 = { class: "flex flex-none pr-[360px] pl-[180px] h-[60px] border-t border-slate-200 border-solid" }
const _hoisted_8 = {
  key: 0,
  class: "absolute w-full h-full top-[0px] left-[0px] flex justify-center items-center"
}

import { ref, reactive, onMounted, nextTick, createVNode, h } from 'vue'
import WebHeader from './components/Header.vue'
import SideLeft from './components/SideLeft.vue'
import Core from '../Pixi/components/core.vue'
import Opera from './components/Opera.vue'
import FloatTool from './components/FloatTool.vue'
import Underside from './components/Underside.vue'
import Floor from './components/Floor.vue'
import CheckboxImage from '@/components/CheckboxImage/index.vue'
import Sku from '@/components/Sku/components/wu-sku/wu-sku.vue'
import {
  Spin,
  message,
  Modal as AModal,
  Button as AButton
} from 'ant-design-vue';

import { useRoute } from 'vue-router'
import { IAddBgType, ISpriteType, ITabType, IConfigFontStyle, IImageSprite, ISubmitParams, IParams, ISpriteIndexType, CutoutType } from '@/types'
import { dividerProps, showConfirmDialog, showDialog, Dialog, Notify, Loading, Swipe, SwipeItem } from 'vant';
import type { ICoreAppList, MirrorType, RelationProduct } from '../Pixi/types'
import { ExclamationCircleOutlined, QuestionCircleOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue'

import { Application, Sprite } from 'pixi.js'
// import Pixi from './components/core'
import { addCartToSY, getDesignSingleApi, getProductApi, submitDesign, updateDesign, batchQueryExternalSkuApi, submitWmHook, } from '@/api/common'
import { addFile } from '@/components/update'
import { getIntegrateUrl, uploadImageToStrapi } from '../Pixi/components/utils'
import { IManySide, IProductInfo } from '../Pixi/types'
import { BgData, SkuFormat, SkuItem } from './types'
import { useI18n } from 'vue-i18n'
import { wmHookDemo } from '@/utils'

interface IEditSprite {
  index: number;
  sprite: Sprite | null
}

type ConfirmAppOption = {
  label: number;
  value: string;
  app: any;
}

const DEFAILT_CURRENT = 0

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {},
  emits: {},
  setup(__props, { expose: __expose, emit: __emit }) {

const { t } = useI18n()
const props = __props
const emits = __emit
const route = useRoute()
const [messageApi, contextHolder] = message.useMessage();

const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '48px',
  },
  spin: true,
});
const editSprite = reactive<IEditSprite>({
  index: 0,
  sprite: null
})
const tabType = ref<ITabType>('image')
const tabTypeChnagePrevType = ref<ITabType>('image')

const onTabTypeChange = (type: ITabType) => {
  tabType.value = type
  if (tabTypeChnagePrevType.value === type) {
    onPancelShowChange(!showPanel.value)
  } else {
    onPancelShowChange(true)
  }
  tabTypeChnagePrevType.value = type
}
const coreRef = ref<HTMLDivElement | null>(null)
const coreRefs = ref<any[]>([])
const coreAppList = ref<ICoreAppList>([])
const core = ref<any>(null)
const addCoreText = async (text: string, fontStyle: IConfigFontStyle) => {
  const sprite = await core.value?.app.addText(text || '默認文案', fontStyle)
  core.value?.app.selectSprite(sprite)
  core.value?.app.historyRecord('add', [sprite])
}
const addCoreImage = (url: string) => {
  if (!url) {
    return
  }
  setNotifyLoading(true, '圖片加載中')
  core.value?.app.addImage(url, {
    onCreated: (sprite: IImageSprite) => {
      core.value?.app.historyRecord('add', [sprite])
      setNotifyLoading(false)
    }
  })
}

const addCoreBg = (type: IAddBgType, url: string) => {
  core.value?.app.addBg(type, url)
}
const loadDesign = async (data: IParams, reset: boolean) => {
  setNotifyLoading(true);
  try {
    await core.value?.app.loadDesign(data, reset, () => {
      setNotifyLoading(false);
    })
  } catch(err) {
    console.log(err)
  } finally {
    // setNotifyLoading(false);
  }
}
const setDesginArea = (data: string) => {
  core.value?.app.setDesginArea(data)
}
const setMirror = (type: MirrorType) => {
  const { sprite } = core.value?.app.target
  if (sprite) {
    core.value?.app.mirror(sprite, type)
  }
}

const setSpriteCartoon = () => {
  const { sprite } = core.value?.app.target
  if (sprite) {
    core.value?.app.setSpriteCartoon(sprite)
  }
}

const fillColorSprite = (color: number) => {
  const { sprite } = core.value?.app.target
  if (sprite) {
    core.value?.app.fillColorSprite(sprite, color)
  }
}

const setSpriteTexture = (url: string) => {
  const { sprite } = core.value?.app.target
  if (sprite) {
    core.value?.app.setSpriteTexture(sprite, url)
  }
}

const fillImageSpite = (url?: string) => {
  const { sprite } = core.value?.app.target
  if (sprite) {
    url = url ? getIntegrateUrl(url, `tplv-z8ialgywaa-w-size:${Math.ceil(sprite.width)}`) : undefined
    core.value?.app.fillImageSpite(sprite, url)
  }
}

const copySpite = () => {
  const { sprite } = core.value?.app.target
  if (sprite) {
    core.value?.app.copy(sprite)
  }
}

const setIndex = (type: ISpriteIndexType) => {
  const { sprite } = core.value?.app.target
  if (sprite) {
    core.value?.app.setIndex(sprite, type)
  }
}

const historyBack = (type: ISpriteIndexType) => {
  core.value?.app.historyBack()
}

const historyForward = (type: ISpriteIndexType) => {
  core.value?.app.historyForward()
}

const setSpriteCutout = (cutoutType: CutoutType) => {
  const { sprite } = core.value?.app.target
  if (sprite) {
    core.value?.app.setSpriteCutout(sprite, cutoutType)
  }
}

const getCurrentSprite = () => {
  return true
}

const TYPE_TEXT_MAP = new Map([
  ['image', '照片'],
  ['text', '文字'],
  ['bg', '背景'],
  ['stickers', '貼畫'],
  ['design', '設計'],
  ['layer', '圖層'],
  ['imageFill', '圖片填充'],
  ['colorFill', '顔色填充'],
  ['modal', '款式'],
  ['designArea', '藝術闆'],
])

const operaRef = ref<any | null>(null)

const panelAnimation = ref<string>('')
const showPanel = ref<boolean>(false)
const panel = ref<any>()
const transitionEnd = () => {
  if (panelAnimation.value === 'leave') {
    showPanel.value = false
  }
}
const panelTranstion = () => {
  panel.value?.addEventListener('animationend', transitionEnd)
  panel.value?.addEventListener('webkitAnimationEnd', transitionEnd)
}

const onPancelShowChange = (val: boolean, type?: ITabType) => {
  panelAnimation.value = val ? 'enter' : 'leave'
  if (type) {
    tabType.value = type
  }
  if (val) {
    showPanel.value = true
  }
}

// 操作欄
const textTab = ref<any>()
const showOpera = ref<boolean>(false)
const spriteType = ref<ISpriteType | null>(null)
const formData = reactive({
  text: '',
  fontWeight: '',
  fontFamily: '',
  fontColor: '',
  borderSize: 0,
  borderColor: '',
})
const onSelectSprite = () => {
  const { sprite } = core.value?.app.target
  let { type } = sprite
  if (type === 'text') {
    // formData.text = sprite.text
    operaRef.value?.updateText({
      text: sprite.text,
      fontWeight: sprite.style.fontWeight,
      fill: sprite.style.fill,
      strokeThickness: sprite.style.strokeThickness,
      stroke: sprite.style.stroke,
    })
  } else {
    openImage()
    operaRef.value?.updateImage({
      imageUrl: sprite.url,
      lrMirror: sprite.scale.x < 0,
      tbMirror: sprite.scale.y < 0,
      cartoon: sprite.cartoon,
      cutoutType: sprite.cutoutType,
      cutout: sprite.cutout,
      imageFill: sprite.hasImageFill ? sprite.imageFillUrl : '',
      fillColor: sprite.fillColor,
    })
  }
  showOpera.value = true
}

// update tab data
const updateTabData = () => {
  const target = core.value?.app.target
  if (!target || !target.sprite) {
    return
  }
  const { sprite } = target
  if (sprite.type === 'text' && textTab.value) {
    textTab.value?.update()
  }
}

const onCancelSprite = () => {
  operaRef.value?.reset()
}
const onReset = () => {
  Modal.confirm({
    title: '是否確認清空所有設計？',
    icon: createVNode(ExclamationCircleOutlined),
    content: createVNode('div', { style: 'color:red;' }, '清空後不可恢復'),
    okText: '確定',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      console.log('OK');
      core.value?.app.reset()
    },
    onCancel() {
      console.log('Cancel');
    },
  })
}

const submitSuccess = () => {
  Modal.success({
    title: '提示',
    icon: createVNode(ExclamationCircleOutlined),
    content: '商品已成功加入購物車！',
    okText: '確定',
  })
  // showDialog({
  //   title: '提示',
  //   message: '作品提交成功',
  // }).then(() => {
  //   // on close
  // });
}
const temImage = ref<string[]>([])
const submitSuccessVisible = ref(false)
const submitRequest = async (params: ISubmitParams) => {
  try {
    let { design_id, app_id: appId, cartid: cartId } = route.query
    let ajax = design_id ? updateDesign : submitDesign
    console.log('111')
    const result = await ajax(params, Number(design_id))
    console.log('222', result)
    
    // 加入购物车-shopify
    let attributes = [
      {
        key: `_preview_image_url1`,
        value: getIntegrateUrl(params.apps?.[0]?.effectImage || '')
      },
      {
        key: '_artwork_id',
        value: result.data,
      }
    ]
    await addCartToSY({
      appId,
      cartId,
      // lines,
      lines: [
        {
          merchandiseId: selectOtherSku.value.sku.relation_id,
          quantity: 1,
          attributes,
        }
      ]
    })
  } catch(err) {
    console.log(err)
  }
}

const expand = ref(false)
const onPanelExpand = () => {
  expand.value = !expand.value
}

const showNotifyLoading = ref(false)
const textNotifyLoading = ref('')
const setNotifyLoading = (val: boolean, text?: string) => {
  showNotifyLoading.value = val
  if (text) {
    textNotifyLoading.value = text
  } else {
    textNotifyLoading.value = ''
  }
}

const layerRef = ref<any>(null)
const onDelSprite = () => {
  layerRef.value?.update?.()
}
const onAddSprite = () => {
  layerRef.value?.update?.()
}

const disabledBack = ref(false)
const disabledForward = ref(true)
const setHistoryBtn = (backVisible: boolean, forwardVisible: boolean) => {
  disabledBack.value = backVisible
  disabledForward.value = forwardVisible
}
// 後退
const onHistoryBack = () => {
  core.value?.app.historyBack()
}
// 恢複
const onHistoryForward = () => {
  core.value?.app.historyForward()
}
const updateTextSprite = (text: string, fontStyle: IConfigFontStyle) => {
  const { target } = core.value?.app as any
  if (!target.sprite) {
    return
  }
  core.value?.app.historyRecord('update', [target.sprite])
  core.value?.app.updateTextSprite(target.sprite, text, fontStyle)
}

const cutImage = reactive({
  url: '',
  onDone: (base64: string) => {}
})
const setCutImageUrl = (url: string, onDone: (base64: string) => void) => {
  cutImage.url = url
  cutImage.onDone = onDone
}

const designAreaList = ref<string[]>([])

const previewSwiperRef = ref<any>(null)
const previewPrevDiasbled = ref<boolean>(true)
const previewNextDiasbled = ref<boolean>(true)
const updatePreviewSwitchBtnDisabled = (index: number) => {
  previewPrevDiasbled.value = index === 0
  previewNextDiasbled.value = index >= temImage.value.length - 1
}
const onPreviewChange = (index: number) => {
  updatePreviewSwitchBtnDisabled(index)
}
const onPreviewSwitch = (type: 'prev' | 'next') => {
  switch(type) {
    case 'prev':
      !previewPrevDiasbled.value && previewSwiperRef.value.prev()
      break
    case 'next':
      !previewNextDiasbled.value && previewSwiperRef.value.next()
      break
  }
}

const coreIndex = ref<number>(0)
const initCore = async (many_side: IManySide, index: number, design?: any) => {
  if (coreRefs.value.length) {
    let rect = coreRef.value?.getBoundingClientRect?.()
    core.value = coreRefs.value[index]
    coreIndex.value = index
    const side = many_side[coreIndex.value]
    await coreRefs.value?.[index].init({
      width: rect?.width || 0,
      height: rect?.height || 0,
      ...side,
      // designImage: many_side[coreIndex.value].designImage[0]
      designImage: many_side[index].designImage[0]
    }, design, 'web', {
      initialImageWidth: 100,
      expandParams: {
        sideName: side.sideName,
        relation: side.relation,
      },
    })
  }
}

const setManySide = async (index: number) => {
  setNotifyLoading(true)
  const ref = coreRefs.value[index]
  let many_side = productInfo.value?.many_side
  let side = coreAppList.value[index]
  core.value = ref
  coreIndex.value = index
  designAreaList.value = side.designImage
  operaRef.value?.setDesignAreaList(side.designImage)

  if (!core.value.app) {
    many_side && await initCore(many_side, coreIndex.value)
  }
  setHistoryBtn(
    ref.app.history.revokeList.length === 0,
    ref.app.history.restoreList.length === 0
  )
  setNotifyLoading(false)
}


let skuShow = ref(false);
// sku列錶
let skus = ref<SkuFormat[]>([])
const relationProduct = ref<RelationProduct | undefined>()
// const selectSku = ref('')
const selectOtherSku = ref()
const selectedSkuIndex = ref()

let skuConfirm = async (e: any) => {
  setNotifyLoading(true)
  skuShow.value = false
  let { id: sku, design_sku } = e.sku
  selectOtherSku.value = e
  const findSkuIndex = skus.value.findIndex(t => t.id === e.sku.id)
  console.log(skus.value[findSkuIndex], '-e')
  selectedSkuIndex.value = findSkuIndex
  core.value?.app.app.destroy(true)
  // await loadApplication(String(sku), route.query.design_id as string | undefined)
  await loadApplication(design_sku)
  setNotifyLoading(false)
}
const openSku = (data: SkuFormat[], product: RelationProduct) => {
  console.log('data', data)
  skus.value = data
  relationProduct.value = product
  skuShow.value = true
}

const setCoreScale = () => {
  core.value.app.setStageScale()
}

const designInfo = ref()
const getDesignInfo = () => {
  return new Promise(async (resolve, reject) => {
    let id: any = route.query.design_id
    if (id) {
      const { data } = await getDesignSingleApi(id)
      designInfo.value = data
      resolve(data)
    }
  })
}
const productInfo = ref<IProductInfo | undefined>()
const getProductInfo = async (design_sku) => {
  try {
    // const { data } = await getProductApi(sku as string)
    const data = design_sku
    data.many_side = data.design_areas.map(t => ({
      ...t,
      designImage: t.design_image,
      effectImage: t.effect_image,
      productImage: t.product_image,
      sideName: t.name,
    }))

    productInfo.value = data
    coreAppList.value = data.many_side
    designAreaList.value = data.many_side[0].designImage
  } catch(err) {
    console.log(err)
  }
}

const confirmAppVisible = ref(false)
const confirmAppList = ref<ConfirmAppOption[]>([])
const selectedAppOptions = ref<string[]>([])

const onConfirmAppCancel = () => {
  confirmAppVisible.value = false
}
const onConfirmAppOk = () => {
  confirmAppVisible.value = false
  const selectedApps = selectedAppOptions.value.map(value => {
    return confirmAppList.value.find(c => value === c.value).app
  })
  saveSubmit(selectedApps)
}

const checkSideNumber = ref(0)
const checkAppSideNumber = () => {
  const { side_number } = productInfo.value
  const completedApps = coreRefs.value.filter(t => t?.isInitCompleted)
  checkSideNumber.value = side_number
  if (completedApps.length > side_number) {
    confirmAppList.value = completedApps.map((t, index) => ({
      label: index,
      value: t?.app.generateEffectImage() as string,
      app: t,
    }))
    confirmAppVisible.value = true
    console.log('设计多了')
  } else if (completedApps.length < side_number) {
    console.log('设计少了')
    message.error(`${t('submition_hint_before')}${side_number}${t('submition_hint_after')}`);
  } else {
    return true
  }
}

const onSubmit = () => {
  
  Modal.confirm({
    title: '是否確定提交並加入購物車？',
    icon: createVNode(QuestionCircleOutlined),
    // content: createVNode('div', { style: 'color:red;' }, '清空後不可恢復'),
    okText: '確定',
    // okType: 'danger',
    cancelText: '取消',
    onOk() {
      if (!checkAppSideNumber()) {
        return
      }
      saveSubmit()
    },
    onCancel() {
      console.log('Cancel');
    },
  })
}

const saveSubmit = async (appList?: any[]) => {
  setNotifyLoading(true)
  const applicationList: any[] = []
  const refs = appList || coreRefs.value
  
  for (let index = 0; index < refs.length; index++) {
    const t = refs[index];
    // console.log('hiddenShowDesignArea', t.app.hiddenShowDesignArea)
    if (!t.app) {
      break;
    }
    t.app.hiddenShowDesignArea();
    if (t.isInitCompleted) {
      let params = t?.app.getParams() as IParams
      let base64 = t?.app.generateEffectImage() as string
      // let deisgnBase64 = t?.app.generateDeisgnImage() as string

      let [effectImage] = await Promise.all([await addFile(base64)])

      const [effecRes] = await Promise.all([uploadImageToStrapi(effectImage)])

      temImage.value.push(getIntegrateUrl(effectImage, 'tplv-z8ialgywaa-w-size:375'))

      submitSuccessVisible.value = true
      params.effectImage = effectImage
      // params.designImage = designImage
      params.effectImageId = effecRes[0].id
      // params.designImageId = designRes[0].id
      applicationList.push({
        ...params,
        ...(params.expandParams || {}),
        product_id: productInfo.value?.productid,
        show_design_image: t.app.shellShowUrl,
        // children: JSON.stringify(params.children)
      })
    } else {
      // 原數據或者為空
    }
    t?.app.openShowDesignArea();
  }
  try {

    
    console.log('applicationList', applicationList)
    if (applicationList.length) {
      await submitRequest({
        systemSkuId: selectOtherSku.value?.sku.design_sku.id,
        appId: route.query.app_id as string,
        apps: applicationList
      })
    }
    setNotifyLoading(false)
    messageApi.success('提交成功')
    setTimeout(() => {
      window.parent.postMessage('navigate:cart', '*');
    }, 1500)
  } catch(err) {
    console.log(err)
    setNotifyLoading(false)
  }
}

const openDesignArea = (data: string[]) => {
  operaRef.value?.openDesignArea(data)
}

const openPicture = (data: string[]) => {
  operaRef.value?.openPicture(data)
}

const openText = (data: string[]) => {
  operaRef.value?.openText(data)
}

const openImage = () => {
  operaRef.value?.openImage()
}

const onSticker = (data: string[]) => {
  operaRef.value?.onSticker(data)
}

const onBackground = (data: { color: BgData[], image: BgData[] }) => {
  operaRef.value?.onBackground(data)
}

const onDesign = (data: any[]) => {
  operaRef.value?.onDesign(data)
}

const loadApplication = async (design_sku, design_id?: string) => {
  // typeof sku === 'string' && (await getProductInfo(sku))

  getProductInfo(design_sku)
  nextTick(async () => {
    let many_side = productInfo.value?.many_side
    if (design_id) {
      await getDesignInfo()
      many_side && initCore(many_side, DEFAILT_CURRENT, designInfo.value[DEFAILT_CURRENT])
    } else {
      console.log(many_side, '--many_side')
      many_side && initCore(many_side, DEFAILT_CURRENT)
    }
  })
}

onMounted(async () => {
  let { design_id, sku, pid } = route.query
  
  panelTranstion()
  // typeof sku === 'string' && loadApplication(sku, design_id as string | undefined)
  fetch('https://plugcustom.com.cn/tos-cn-i-z8ialgywaa/64f1a535ec6f437ab957db8c881acf3b~tplv-z8ialgywaa-w-size:300.image').then(res => {
    console.log(res)
  })
  // submitWmHook({
  //   msgBody: JSON.stringify({
  //     orderNo: '1512007883991481'
  //   }),
  //   event: 'create'
  // })
})

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(WebHeader, {
        text: selectOtherSku.value ? `${relationProduct.value.name} / ${ selectOtherSku.value?.skuText.join(';') }` : '選擇産品',
        onSubmit: onSubmit,
        onHistoryBack: historyBack,
        onHistoryForward: historyForward,
        onReset: onReset
      }, null, 8, ["text"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(SideLeft, {
          data: coreAppList.value[core.value?.index || 0],
          onOpenDesignArea: openDesignArea,
          onOpenPicture: openPicture,
          onOpenText: openText,
          onAddText: addCoreText,
          onOnSticker: onSticker,
          onOnBackground: onBackground,
          onOnDesign: onDesign,
          onOpenSku: openSku
        }, null, 8, ["data"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "h-full w-[90%] relative",
          ref_key: "coreRef",
          ref: coreRef
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(coreAppList.value, (item, index) => {
            return _withDirectives((_openBlock(), _createElementBlock("div", {
              class: "core-app",
              key: index
            }, [
              _createVNode(Core, {
                ref_for: true,
                ref: (ref) => (coreRefs.value[index] = ref),
                coreData: item,
                coreIndex: index,
                isCurrent: core.value?.index === index,
                onOnSelect: onSelectSprite,
                onOnCancel: onCancelSprite,
                onOnAdd: onAddSprite,
                onOnDel: onDelSprite,
                onSetHistoryBtn: setHistoryBtn,
                onUpdateTabData: updateTabData
              }, null, 8, ["coreData", "coreIndex", "isCurrent"])
            ])), [
              [_vShow, core.value?.index === index]
            ])
          }), 128))
        ], 512),
        _createVNode(FloatTool, {
          onCopySpite: copySpite,
          onSetIndex: setIndex
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(Opera, {
          ref_key: "operaRef",
          ref: operaRef,
          formData: formData,
          onAddText: addCoreText,
          onAddImage: addCoreImage,
          onAddBackground: addCoreBg,
          onLoadDesign: loadDesign,
          onSetDesginArea: setDesginArea,
          onUpdateTextSprite: updateTextSprite,
          onSetMirror: setMirror,
          onSetSpriteCutout: setSpriteCutout,
          onSetSpriteCartoon: setSpriteCartoon,
          onFillColorSprite: fillColorSprite,
          onSetSpriteTexture: setSpriteTexture,
          onFillImageSpite: fillImageSpite
        }, null, 8, ["formData"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(Underside, {
        list: coreAppList.value,
        onSwitchSide: setManySide
      }, null, 8, ["list"])
    ]),
    (showNotifyLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_unref(Spin), {
            tip: "Loading...",
            indicator: _unref(indicator)
          }, null, 8, ["indicator"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_unref(contextHolder)),
    _createVNode(Sku, {
      modelValue: _unref(skuShow),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(skuShow) ? (skuShow).value = $event : skuShow = $event)),
      data: _unref(skus),
      platform: "web",
      themeColor: [226, 35, 26],
      defaultCover: relationProduct.value?.image_id,
      selectSkuIndex: selectedSkuIndex.value,
      btnConfirmText: "確定定製",
      notSelectSku: "請選擇完整的商品信息",
      onConfirm: _unref(skuConfirm)
    }, null, 8, ["modelValue", "data", "defaultCover", "selectSkuIndex", "onConfirm"]),
    _createVNode(_unref(AModal), {
      open: confirmAppVisible.value,
      "onUpdate:open": _cache[2] || (_cache[2] = ($event: any) => ((confirmAppVisible).value = $event)),
      title: `${_unref(t)('submit_warning_before')}${checkSideNumber.value}${_unref(t)('submit_warning_after')}`,
      onOk: onConfirmAppOk
    }, {
      footer: _withCtx(() => [
        _createVNode(_unref(AButton), {
          key: "back",
          onClick: onConfirmAppCancel
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("取消")
          ])),
          _: 1
        }),
        _createVNode(_unref(AButton), {
          key: "submit",
          type: "primary",
          disabled: selectedAppOptions.value.length !== checkSideNumber.value,
          onClick: onConfirmAppOk
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("确定")
          ])),
          _: 1
        }, 8, ["disabled"])
      ]),
      default: _withCtx(() => [
        _createVNode(CheckboxImage, {
          value: selectedAppOptions.value,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((selectedAppOptions).value = $event)),
          options: confirmAppList.value,
          max: checkSideNumber.value
        }, null, 8, ["value", "options", "max"])
      ]),
      _: 1
    }, 8, ["open", "title"])
  ]))
}
}

})