import { del, get, post } from '@/api'
import { IDesignParams, IPageParams, IStickerParams, IStickerSearchParams, ISubmitParams } from '@/types'
import { uploadResoureceParams } from './types'

export const uploadResourece = (params: uploadResoureceParams) => {
  // return post('/api/user_resources', params)
  return post('/api/user-resource/add-user-resource', params)
}

export const uploadImage = (params: any) => {
  // return post('/api/user_resources', params)
  return post('/api/upload/', params)
  // return post('/api/user-resource/upload-image', params)
}

export const getUserResrouceApi = (): Promise<any> => {
  // return get('/api/get_user_resource')
  return post('/api/user-resource/find-user-resources', {})
}

export const submitDesign = (params: ISubmitParams, id: number): Promise<any> => {
  // return post('/api/customdesign/create', params)
  return post('/api/custom-design/add', params)
}

export const submitWmHook = (params: any): Promise<any> => {
  return post('/api/shopify/webhook', params)
}

export const updateDesign = (params: ISubmitParams, id: number): Promise<any> => {
  return post(`/api/customdesign/update`, params)
}

export const addCartToSY = (params: any): Promise<any> => {
  // return post(`/api/shopify/add_to_cart`, params)
  return post(`/api/shopify/add-cart`, params)
}

export const getStickerSearchApi = (params: IStickerSearchParams): Promise<any> => {
  // return get('/api/stickers/search', { params })
  return post('/api/stickers/find-list', params)
}

export const getStickerCategoryApi = (): Promise<any> => {
  // return get('/api/stickers-category/list', { params })
  return post('/api/stickers-category/find-list', {})
}

export const getBackgroundApi = (params: IPageParams): Promise<any> => {
  // return get('/api/front-end-tool-palette/list', { params })
  return post('/api/background/find-list', params)
}

export const getDesignApi = (params: IDesignParams): Promise<any> => {
  return post('/api/custom-design/find-publishing', params)
}

export const getCustomDesign = (id: number): Promise<any> => {
  return get(`/api/custom-designs/${id}`)
}

export const getDesignSingleApi = (id: number): Promise<any> => {
  return get(`/api/customdesign/get/${id}`)
}

export const getProductApi = (params: any): Promise<any> => {
  // return get(`/api/product/get-sku/${sku}`)
  return post(`/api/sku/findSku`, params)
}

export type getCategoryListApiArgs = {
  app_id: string;
}
export const getCategoryListApi = (params: getCategoryListApiArgs): Promise<any> => {
  // return get(`/api/stickers/get-subject`)
  return post('/api/stickers-category/find-list-web', params)
}

export const getProductSkuApi = (id: string, appId: string, currency: string): Promise<any> => {
  // return post(`/api/search_product`, {
  return post(`/api/external-product/get_product`, {
    goodsId: id,
    appId,
    currency,
  })
}

export type addCartItemApiArgs = {
  items: {
    pcaId: string;
    skuId: string;
    productId: string;
    quantity: number;
    customDesignId: number;
    effectImage: string[];
    itemName: string;
    skuName: string;
  }[]
}
export const addCartItemApi = (params: addCartItemApiArgs): Promise<any> => {
  // return post('/api/cart/cart/items', params)
  return post('/api/cart/add-cart-item', params)
}

export const getCartByIdApi = (id: number): Promise<any> => {
  return get('/api/cart/cart/getCartById', { params: {id} })
}

export const getCartApi = (): Promise<any> => {
  return post('/api/cart/find-cart', {})
  // return get('/api/cart/cart/items', {
  //   params: {
  //     id,
  //   }
  // })
}
export const delCartItemApi = (id: string): Promise<any> => {
  return del(`/api/cart/cart/items?id=${id}`, {
    
  })
}

export type addCartItemToWMApiParams = {
  goodsId: string;
  skuId: string;
  bizWid: string;
  quantity: number;
  cloudCustom: string;
  appId: string;
}
export const addCartItemToWMApi = (params: addCartItemToWMApiParams): Promise<any> => {
  // return post(`/api/add_to_cart`, params)
  return post(`/api/cart/add-cart-item-to-wm`, params)
}

export const batchQueryExternalSkuApi = (params: { ids: string }) => {
  return get('/api/product/batch-query-external-sku', { params })
}

export const getBranchInfoByAppIdApi = (params: { ids: string }) => {
  // return get('/api/branch/getBranchInfoByAppId', { params })
  return get('/api/branch/get_branch', { params })
}

export const getSystemSkuApi = (params: { id: number }) => {
  return post('/api/sku/find-sku', params)
}

export const getCustomDesignApi = (params: { id: number }) => {
  return post('/api/custom-design/find-custom-design', params)
}