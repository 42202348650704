import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref, reactive, onMounted } from 'vue'
import Core from '../Pixi/components/core.vue'
import { getCustomDesignApi, getSystemSkuApi } from '@/api/common';
import { useRoute } from 'vue-router';
import { addFile } from '@/components/update'
// import { uploadImageToStrapi } from '../Pixi/components/utils';
import { Icon } from 'vant';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const route = useRoute();
const coreRef = ref<any>()
const core = ref<any>(null)
const designUrl = ref('')

const getCustomDesignInfo = async (id: string) => {
  try {
    const result = await getCustomDesignApi({ id: Number(id) })
    console.log(result)
    // data.value = result;
    return result
  } catch(err) {}
}

const generateStart = async (id: string, callbackName?: string) => {
  try {
    if (core.value.app) {
      core.value.app.clearStage();
      // @ts-ignore
      window.logEvent?.({
        eventName: 'load',
        eventDesc: '已完成清除上一个任务资源。'
      });
    }
    const data = await getCustomDesignInfo(id);
    // @ts-ignore
    window.logEvent?.({
      eventName: 'load',
      eventDesc: '获得作品数据，开始渲染webgl...'
    });
    
    let rect = coreRef.value?.getBoundingClientRect?.()
    if (!rect?.width) {
      return
    }
    const designArea = data.sku.design_areas;
    // tos-cn-i-z8ialgywaa/beb8473f0a494762b0de8a162183c318
    const urlReg = /tos-cn-i-[a-z0-9]+\/[a-z0-9]+/;
    
    await core.value.init({
      width: rect?.width,
      height: rect?.height,
      designImage: designArea.design_image.url.match(urlReg)[0],
      effectImage: designArea.shell_image.url.match(urlReg)[0],
      productImage: designArea.product_image.url.match(urlReg)[0],
      sideName: designArea.name,
      isLoadDesignArea: false,
    }, undefined, 'web', {
      multiple: data.sku.multiple,
    })
    // @ts-ignore
    window.logEvent?.({
      eventName: 'load',
      eventDesc: '舞台、产品加载完成，开始加载作品内容...'
    });
    data.customDesign.children = data.customDesign.children.map((t) => ({
      ...t,
      isLoadOrigin: true,
    }))
    core.value.app.loadDesign(data.customDesign, undefined, async () => {
      setTimeout(async () => {
        core.value.app.productSprite.visible = false;
        core.value.app.shellSprite.visible = false;
  
        const designImageBase64 = core.value.app.generateDeisgnImageFromGenerate();
        const image = await addFile(designImageBase64)
  
        // const result = await uploadImageToStrapi(image);
        console.log('image', image)
        designUrl.value = image;
  
        // @ts-ignore
        window[callbackName]?.(image);
        // @ts-ignore
        window.logEvent?.({
          eventName: 'load',
          eventDesc: `作品内容加载完成，结束任务,callbackName=${callbackName}`
        });
      }, 500)
    });
  } catch(err) {
    // @ts-ignore
    window[callbackName]?.(image);
    // @ts-ignore
    window.logEvent?.({
      eventName: 'error',
      eventDesc: '生成异常！'
    });
  }
}

// @ts-ignore
window.loadWorkDesign = async ({ id, callbackName }) => {
  // console.log('id', id)
  // @ts-ignore
  window.logEvent?.({
    eventName: 'load',
    eventDesc: `获取到作品ID：${id}，开始获取作品数据...`,
  });
  id && generateStart(id, callbackName)
}

onMounted(async () => {
  // @ts-ignore
  window.logEvent?.({
    eventName: 'initLoaded',
    eventDesc: '浏览器应用初始化成功'
  });
  const id = route.query.custom_design_id
  id && generateStart(id as string)
});

const handleCopy = async () => {
  try {
    await navigator.clipboard.writeText(designUrl.value);
    // messageApi.success("複製成功!");
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "generate",
    ref_key: "coreRef",
    ref: coreRef
  }, [
    (designUrl.value)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: "success-text",
          onClick: handleCopy
        }, [
          _createTextVNode("上傳成功，圖片地址：" + _toDisplayString(designUrl.value) + " ", 1),
          _createVNode(_unref(Icon), { name: "link-o" })
        ]))
      : _createCommentVNode("", true),
    _createVNode(Core, {
      ref_key: "core",
      ref: core
    }, null, 512)
  ], 512))
}
}

})