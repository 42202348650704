<template>
  <div ref="threeContainer" class="three-container"></div>
</template>

<script>
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

export default {
  name: 'ThreeScene',
  mounted() {
    this.initThree();
  },
  data() {
    return {
      // scene: unde,
      camera: null,
      renderer: null,
      controls: null,
      dragControls: null,
      model: null,
      isDragging: false,
      previousMousePosition: {
        x: 0,
        y: 0
      }
    };
  },
  methods: {
    initThree() {
      // 创建场景
      this.scene = new THREE.Scene();

      // 创建相机
      this.camera = new THREE.PerspectiveCamera(75, this.$refs.threeContainer.clientWidth / this.$refs.threeContainer.clientHeight, 0.1, 1000);
      this.camera.position.set(0, 0, 10); // 调整相机位置以便更好地看到模型
      const target = new THREE.Vector3(20, 0, 0); // 让相机看向 (0, 5, 0)

      // 设置相机朝向
      this.camera.lookAt(target);

      // 创建渲染器
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setSize(this.$refs.threeContainer.clientWidth, this.$refs.threeContainer.clientHeight);
      this.renderer.setPixelRatio(window.devicePixelRatio); // 高分辨率屏幕支持
      // this.renderer.setClearColor(0x000000, 0); // 设置背景颜色和透明
      // this.renderer.shadowMap.enabled = true; // 启用阴影度
      this.$refs.threeContainer.appendChild(this.renderer.domElement);

      // 添加轨道控制器
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true; // 启用阻尼（惯性），以获得更平滑的控制体验
      this.controls.dampingFactor = 0.25;
      this.controls.screenSpacePanning = false;
      // this.controls.maxPolarAngle = 1; // 限制垂直旋转的最大角度
      // this.controls.minPolarAngle = 1; // 限制垂直旋转的最小角度

      this.controls.minPolarAngle = Math.PI / 3; // 最小垂直角度 (30度)
      this.controls.maxPolarAngle = Math.PI / 2; // 最大垂直角度 (90度)
      // this.controls.enableRotate = false

      // 窗口大小调整处理
      window.addEventListener('resize', this.onWindowResize);
      // 加载纹理
      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load('https://web.plugcustom.com.cn/BAKALU%2001.png', (texture) => {
        texture.encoding = THREE.SRGBColorSpace;
        texture.needsUpdate = true;
      });
      // texture.wrapS = THREE.RepeatWrapping; // 水平重复
      // texture.wrapT = THREE.RepeatWrapping; // 垂直重复
      // texture.repeat.set(2, 2);             // 设置重复次数
      // texture.offset.set(0.1, 0.1);         // 设置偏移

      // 加载远程 FBX 模型
      const loader = new FBXLoader();
      // loader.load('https://web.plugcustom.com.cn/Tiny_bottle_long_box.fbx', (fbx) => {
      loader.load('https://plugcustom-web.oss-cn-shenzhen.aliyuncs.com/model/11oz-Mug/11oz-Mug.fbx', (fbx) => {
        fbx.traverse((child) => {
          if (child.isMesh) {
            child.castShadow = true; // 使模型投射阴影
            child.receiveShadow = true; // 使模型接收阴影
            child.geometry.center(); // 将几何体的中心设置为原点
            
           
            // child.material = new THREE.MeshLambertMaterial({
            //   map: texture,
            // });
            // child.material = new THREE.MeshBasicMaterial({
            //   map: texture,
            //   // color: 0xfff000
            // });


            // new
            child.material = new THREE.MeshPhongMaterial({
              map: texture,
              color: 0xfff000
            });

            // child.material = new THREE.MeshStandardMaterial({
            //   map: texture,
            // });
            // child.material.color.set(0x00ff00);

            // child.material.map = texture;  // 应用贴图
            // child.material.color.set(0x999999); // 确保颜色为白色
            // child.material.needsUpdate = true;  // 更新材质
          }
        });
        fbx.scale.set(0.4, 0.4, 0.4); // 将模型缩小到原来的50%
        fbx.position.set(0, 0, 0)
        this.scene.add(fbx);
        this.model = fbx

        // 计算模型的包围盒
        const boundingBox = new THREE.Box3().setFromObject(fbx);
        this.boundingBox = boundingBox.getSize(new THREE.Vector3());
        let scale = this.boundingBox.x / this.boundingBox.y
        console.log('Model Size:', this.boundingBox, scale); // 打印模型的尺寸
        // 模型加载成功后重新渲染
        this.renderer.render(this.scene, this.camera);
      }, (xhr) => {
        console.log(`${(xhr.loaded / xhr.total * 100).toFixed(2)}% loaded`);
      }, (error) => {
        console.error('An error happened', error);
      });


      // 添加光源
      const ambientLight = new THREE.AmbientLight(0xffffff, 3); // 环境光
      this.scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5); // 方向光
      directionalLight.position.set(0, 1, 1).normalize();
      directionalLight.castShadow = true;
      this.scene.add(directionalLight);

      // const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 2);
      // hemisphereLight.position.set(0, 200, 0);
      // this.scene.add(hemisphereLight);

      // const pointLight1 = new THREE.PointLight(0xffffff, 100, 100);
      // pointLight1.position.set(0, 2, 10);
      // pointLight1.castShadow = true; // 点光源投射阴影
      // this.scene.add(pointLight1);

      // const axesHelper = new THREE.AxesHelper(250);
      // this.scene.add(axesHelper);

      // const pointLightHelper = new THREE.PointLightHelper(pointLight1);
      // this.scene.add(pointLightHelper);

      // 添加地面
      // const ground = new THREE.Mesh(
      //   new THREE.PlaneGeometry(500, 500),
      //   new THREE.MeshLambertMaterial({ color: 0xffffff })
      // );
      // ground.rotation.x = -Math.PI / 2;
      // ground.position.y = -1;
      // ground.receiveShadow = true;
      // this.scene.add(ground);

      // 添加鼠标事件监听
      // this.addMouseEvents();

      // 开始动画循环
      this.animate();
    },
    addMouseEvents() {
      const container = this.$refs.threeContainer;
      container.addEventListener('touchstart', this.onMouseDown, false);
      container.addEventListener('touchmove', this.onMouseMove, false);
      container.addEventListener('touchend', this.onMouseUp, false);
      // container.addEventListener('mouseleave', this.onMouseUp, false);
    },
    onMouseDown(event) {
      const { clientX, clientY } = event.touches[0]
      this.isDragging = true;
      this.previousMousePosition = {
        x: clientX,
        y: clientY
      };
    },
    onMouseMove(event) {
      if (!this.isDragging) {
        return;
      }
      const { clientX, clientY } = event.touches[0]
      const deltaMove = {
        x: clientX - this.previousMousePosition.x,
        y: clientY - this.previousMousePosition.y
      };

      const rotationSpeed = 0.015;

      if (this.model) {
        this.model.rotation.y += deltaMove.x * rotationSpeed;
        // this.model.rotation.x += deltaMove.y * rotationSpeed;
      }

      this.previousMousePosition = {
        x: clientX,
        y: clientY
      };
    },
    onMouseUp() {
      this.isDragging = false;
    },
    animate() {
      requestAnimationFrame(this.animate);

      // 更新控制器
      this.controls.update();

      // 渲染场景
      this.renderer.render(this.scene, this.camera);
    },
    onWindowResize() {
      this.camera.aspect = this.$refs.threeContainer.clientWidth / this.$refs.threeContainer.clientHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.$refs.threeContainer.clientWidth, this.$refs.threeContainer.clientHeight);
      this.renderer.render(this.scene, this.camera);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  }
}
</script>

<style>
.three-container {
  width: 100%;
  height: 100%;
  display: block;
  /* background-color: #eeeeee; */
  /* background-color: #000; */
}
</style>
