import { uploadImage } from "@/api/common"
import { IImageContainer, ISpriteType } from "@/types"
import * as PIXI from 'pixi.js'
import { Sprite } from "pixi.js"

/**
 * 将url地址转成base64图片
 * @param {*} url 
 * @param {*} noBase64 
 * @param {*} dom 
 */
export const convertImgToBase64 = function (url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!url) {
      resolve(url)
      return
    }
    if (url.slice(0, 21) === 'data:image/png;base64') {
      resolve(url)
      return
    }
    let canvas: any = document.createElement('canvas'),
    ctx = canvas.getContext('2d'),
    img = new Image()
    img.crossOrigin = 'Anonymous'
    img.onload = function () {
      canvas.height = img.height
      canvas.width = img.width
      ctx.drawImage(img, 0, 0)
      let dataURL = canvas.toDataURL('image/' + url.substring(url.lastIndexOf("/") + 1, url.length))
      canvas = null
      resolve(dataURL)
    }
    img.onerror = function (err) {
      if (img.src.indexOf('?random=') !== -1) {
        reject(err)
      } else {
        img.src = url + '?random=' + Math.random() * 99999;
      }
    }
    img.src = url;
  })
}

export type getPlatformEventNameRet = {
  startEventName: string;
  moveEventName: string;
  endEventName: string;
}
export const usePlatformEventName = (type: 'web' | 'mobile'): getPlatformEventNameRet => {
  let startEventName = ''
  let moveEventName = ''
  let endEventName = ''
  if (type === 'web') {
    startEventName = 'mousedown'
    moveEventName = 'mousemove'
    endEventName = 'mouseup'
  } else {
    startEventName = 'touchstart'
    moveEventName = 'touchmove'
    endEventName = 'touchend'
  }
  return {
    startEventName,
    moveEventName,
    endEventName
  }
}

export const tap = function(ele: any, type: 'web' | 'mobile', callback: () => void) {
  let tapStartTime = 0,
      tapEndTime = 0,
      tapTime = 500, //tap等待时间，在此事件下松开可触发方法
      tapStartClientX = 0,
      tapStartClientY = 0,
      tapEndClientX = 0,
      tapEndClientY = 0,
      tapScollHeight = 15, //水平或垂直方向移动超过15px测判定为取消（根据chrome浏览器默认的判断取消点击的移动量)
      cancleClick = false;
  let fn = ele.nodeType ? 'addEventListener' : 'on'
  let { startEventName, moveEventName, endEventName } = usePlatformEventName(type)
  ele[fn](startEventName, function(event: any) {
    tapStartTime = event.timeStamp
    let touch = event.changedTouches ? event.changedTouches[0] : event
    tapStartClientX = touch.clientX
    tapStartClientY = touch.clientY
    cancleClick = false
  })
  ele[fn](moveEventName, function(event: any) {
    let touch = event.changedTouches ? event.changedTouches[0] : event
    tapEndClientX = touch.clientX
    tapEndClientY = touch.clientY
    if ((Math.abs(tapEndClientX - tapStartClientX) > tapScollHeight) || (Math.abs(tapEndClientY - tapStartClientY) > tapScollHeight)) {
      cancleClick = true
    }
  })
  ele[fn](endEventName, function(event: any) {
    tapEndTime = event.timeStamp
    if (!cancleClick && (tapEndTime - tapStartTime) <= tapTime) {
      callback()
    }
  })
}

export function generateTexture(sprite: Sprite) {
  let canvas = document.createElement("canvas");
  canvas.width = sprite.width;
  canvas.height = sprite.height;
  canvas.style.width = "100%";
  // document.body.appendChild(canvas)
  let ctx = canvas.getContext("2d");
  if (!ctx) {
    return
  }
  ctx.fillStyle = "red";
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  ctx.globalCompositeOperation = 'destination-in';
  // @ts-ignore
  ctx.drawImage(sprite.texture.baseTexture.resource.source, 0, 0, canvas.width, canvas.height)
  return PIXI.Texture.from(canvas)
}

interface IConfig {
  width: number
  height: number
}
// export function generateTexture(url: string, config: IConfig): Promise<PIXI.Texture<PIXI.Resource>> {
//   let canvas = document.createElement("canvas");
//   // canvas.width = sprite.width;
//   // canvas.height = sprite.height;
//   canvas.width = config.width;
//   canvas.height = config.height;
//   canvas.style.width = "100%";
//   // document.body.appendChild(canvas)
//   let ctx = canvas.getContext("2d");
  
//   // @ts-ignore
//   // ctx.drawImage(sprite.texture.baseTexture.resource.source, 0, 0, 1000, 1000)
//   return new Promise((resolve, reject) => {
//     let image = new Image()
//     image.onload = () => {
//       if (!ctx) {
//         return
//       }
//       ctx.fillStyle = "red";
//       ctx.fillRect(0, 0, canvas.width, canvas.height)
//       ctx.globalCompositeOperation = 'destination-in';
//       ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
//       resolve(PIXI.Texture.from(canvas))
//     }
//     image.onerror = () => {
//       throw new Error('image load fail')
//     }
//     image.src = url
//   })
// }

function arrayBufferToBase64(buffer: Iterable<number>) {
  var binary = '';
  var bytes = new Uint8Array( buffer );
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode( bytes[ i ] );
  }
  return window.btoa( binary );
}

export { arrayBufferToBase64 }

export function generateUUID() {
  var d = new Date().getTime();
  if (window.performance && typeof window.performance.now === "function") {
      d += performance.now(); //use high-precision timer if available
  }
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}

export const getSpriteTypeValue = (type: ISpriteType | undefined, sprite: any) => {
  switch(type) {
    case 'text':
      return sprite.text
    case 'image':
      return sprite.texture.baseTexture.cacheId
    case 'color':
      return sprite.color
    default:
      return ''
  }
}

export const getIntegrateUrl = (value: string, temp?: string) => {
  return `https://plugcustom.com.cn/${value}~${temp || 'tplv-z8ialgywaa-image'}.webp`
}

export const getSimpleImageTemplateUrl = (url: string) => {
  return /^http/.test(url) ? url.split('plugcustom.com.cn/')?.[1].split?.('~')?.[0] : url
}

export const uploadImageToStrapi = async (url: string, name?: string) => {
  url = /^http(s)?\:\\\\/.test(url) ? url : getIntegrateUrl(url, 'tplv-z8ialgywaa-w-size:240')
  const myImage = await fetch(url);
  const myBlob = await myImage.blob();

  const formData = new FormData();
  formData.append('files', myBlob, `${name || url.split('/')[1]}.png`);
  const res = await uploadImage(formData) as any[]
  return res
}
